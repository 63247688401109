import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { AlertDialog, Button, Flex, Text } from '@radix-ui/themes';
import './appHeader.scss';
import { useEffect, useRef, useState } from 'react';
import { userApi } from '@/services/user';
import { workspaceApi } from '@/services/workspace';
import posthog from 'posthog-js';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import { GenerateVariationsButton } from '@/features/screens/collection/components/view-wrapper/components/GenerateVariationsButton';
import { useMediaQuery } from 'react-responsive';
import { ReactComponent as ProCrownIcon } from '@/assets/icons/pro-crown.svg';
import { setActiveView } from '@/redux/slices/editor';
import { CheckIcon } from '@radix-ui/react-icons';
import { setActiveVariation } from '@/redux/slices/collection';
import { psdEngineApi } from '@/services/psdEngine';
import { ReactComponent as TrashBin } from '@/assets/icons/trash-bin.svg';
import { AppPaymentPopup } from '@/features/screens/workspace/components/app-payments/AppPaymentPopup';
import { setIsAppPaymentPopupOpen } from '@/redux/slices/app';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useCaptureEvent from '@/hooks/useCaptureEvent';
import useAddAiBackgroundToSmartObject from '@/hooks/useAddAiBackgroundToSmartObject';
import { toast } from 'react-toastify';
import { Onboarding } from './Onboarding';
// import { useRegisterSW } from 'virtual:pwa-register/react';
// import { registerSW } from 'virtual:pwa-register';

export const AppHeader = () => {
  const dispatch = useAppDispatch();
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const captureEvent = useCaptureEvent();
  const { isAuthenticated } = useAppSelector((state) => state.authReducer);
  const { isAppPaymentPopupOpen } = useAppSelector((state) => state.appReducer);
  const { currentUser } = useAppSelector((state) => state.userReducer);
  const { isCreatingVariations } = useAppSelector(
    (state) => state.collectionReducer
  );
  const { currentWorkspace } = useAppSelector(
    (state) => state.workspaceReducer
  );
  const { activeView, activeAction } = useAppSelector(
    (state) => state.editorReducer
  );
  const appPaymentPopupRef = useRef();
  const isMobile = useMediaQuery({ query: '(max-width: 480px)' });
  const isAppPro = useFeatureFlagEnabled('app-pro');
  const {
    activeSmartObject,
    activeColorCombination,
    mockupVariations,
    selectedHexColor,
    activeDesignAsset,
    mockup,
  } = useAppSelector((state) => state.collectionReducer);
  const { aiBackgroundImage } = useAppSelector(
    (state) => state.aiBackgroundsReducer
  );
  const [searchParams, setSearchParams] = useSearchParams({});

  const [removeAsset, { isLoading: isDeletingAsset }] =
    psdEngineApi.useDeleteAssetVariationMutation();

  userApi.useGetCurrentUserQuery(null!, {
    skip: currentUser !== null || !isAuthenticated,
  });
  workspaceApi.useGetCurentUserWorkspaceInfoQuery(null!, {
    skip: currentWorkspace !== null || !isAuthenticated,
  });

  const removeDesignAsset = async () => {
    try {
      await removeAsset({ id: activeDesignAsset?.id }).unwrap();
      captureEvent('Design asset removed', {
        id: activeDesignAsset?.id,
      });
      dispatch(setActiveVariation(mockupVariations[0]));
      goBack();
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_design_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const [currentLocation, setCurrentLocation] = useState('');

  useEffect(() => {
    posthog.capture('$pageview');
    setCurrentLocation(location.pathname);
    const paywall = searchParams.get('paywall') as string;

    if (paywall === 'active') {
      captureEvent('Upgrade your plan modal viewed', {
        source: 'Website deep link',
      });
      dispatch(setIsAppPaymentPopupOpen(true));
      searchParams.delete('paywall');
      setSearchParams(searchParams);
    }
  }, [location]);

  const shouldShowHeader = () => {
    return currentLocation.startsWith('/mockup/');
  };

  const [addColor, { isLoading: isAdding }] =
    psdEngineApi.useAddColorCombinationMutation();
  const [updateColor, { isLoading: isUpdating }] =
    psdEngineApi.useUpdateColorCombinationMutation();
  const [removeColor, { isLoading: isDeleting }] =
    psdEngineApi.useDeleteColorVariationMutation();
  const [createMockupVariations, { isLoading: isCreatingMockupVariations }] =
    psdEngineApi.useCreateMockupVariationsMutation();

  const addNewColorVariant = async () => {
    const addedColor = await addColor({
      smart_object_id: activeSmartObject?.id,
      hex_code: selectedHexColor,
    }).unwrap();
    const createdVariations = await createMockupVariations({
      mockup_id: mockup?.id,
      smart_object_id: activeSmartObject.id,
      color_id: addedColor.data.id,
    }).unwrap();
    dispatch(
      setActiveVariation(
        createdVariations.data[createdVariations.data.length - 1]
      )
    );
    goBack();
  };

  const updateColorVariant = async () => {
    await updateColor({
      id: activeColorCombination?.id,
      smart_object_id: activeSmartObject?.id,
      hex_code: selectedHexColor,
    });
    goBack();
  };

  const removeColorVariant = async () => {
    try {
      await removeColor({ id: activeColorCombination?.id }).unwrap();
      captureEvent('Color variant removed', {
        id: activeDesignAsset?.id,
      });
      goBack();
    } catch (error: any) {
      toast.error(error.data.message, {
        toastId: 'remove_color_error',
        position: 'bottom-right',
        autoClose: 7000,
      });
    }
  };

  const getNumerOfAssociatedVariations = () => {
    const associatedVariations = mockupVariations.filter((variation) => {
      return variation.variation_elements.some(
        (element) => element.color_id === activeColorCombination?.id
      );
    });

    return associatedVariations.length;
  };

  const goBack = () => {
    dispatch(setActiveView('preview'));
  };

  useEffect(() => {
    if (isAppPaymentPopupOpen) {
      (appPaymentPopupRef?.current as any)?.openDialog();
    } else {
      (appPaymentPopupRef?.current as any)?.closeDialog();
    }
  }, [isAppPaymentPopupOpen]);

  const { applyOnSmartObject, isApplying } = useAddAiBackgroundToSmartObject();

  const addScene = async () => {
    await applyOnSmartObject(aiBackgroundImage, collectionId as string);
    captureEvent('Ai generated background asset added', {
      collectionId,
      aiBackgroundImage,
    });
    dispatch(setActiveView('preview'));
  };

  // const { needRefresh, offlineReady, updateServiceWorker } = useRegisterSW();
  // const [needManualRefresh, setNeedManualRefresh] = useState(false);
  // const [intervalId, setIntervalId] =
  //   useState<ReturnType<typeof setInterval>>();
  // const [hasError, setHasError] = useState(false);

  // useEffect(() => {
  //   if (needRefresh[0]) {
  //     console.log('needRefresh[0] is true on init');
  //     updateServiceWorker();
  //   }
  // }, [needRefresh]);

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     if (!hasError && navigator.onLine) {
  //       console.log('Checking for service worker update...');
  //       registerSWCheck();
  //     } else {
  //       clearInterval(intervalId);
  //     }
  //   }, 2000);
  //   setIntervalId(id);

  //   return () => {
  //     if (intervalId !== undefined) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [hasError]);

  // const registerSWCheck = async () => {
  //   registerSW({
  //     async onRegisteredSW(swUrl, r) {
  //       if (r) {
  //         try {
  //           if (needRefresh[0]) {
  //             if (intervalId !== undefined) {
  //               clearInterval(intervalId);
  //             }
  //             return;
  //           }

  //           if (!(!r.installing && navigator)) {
  //             throw new Error(`HTTP status NO r.installing || navigator`);
  //           }

  //           if ('connection' in navigator && !navigator.onLine) {
  //             throw new Error(
  //               `HTTP status NO 'connection' in navigator || !navigator.onLine`
  //             );
  //           }

  //           const response = await fetch(swUrl, {
  //             cache: 'no-store',
  //             headers: {
  //               'cache-control': 'no-cache',
  //             },
  //           });

  //           if (!response.ok) {
  //             throw new Error(`HTTP status ${response.status}`);
  //           }

  //           if (!needManualRefresh) {
  //             await r.update();
  //             if (intervalId !== undefined) {
  //               clearInterval(intervalId);
  //             }
  //           } else {
  //             console.log('New worker detected, manual refresh needed.');
  //           }
  //         } catch (error) {
  //           if (navigator.onLine) {
  //             if (!needManualRefresh) {
  //               setNeedManualRefresh(true);
  //               setHasError(true);
  //               if (intervalId !== undefined) {
  //                 clearInterval(intervalId);
  //               }
  //             }
  //             console.log('Error checking for service worker update:', error);
  //           } else {
  //             setNeedManualRefresh(false);
  //             clearInterval(intervalId);
  //             console.log('You are offline.');
  //           }
  //         }
  //       }
  //     },
  //   });
  // };

  //
  // ?topUp=success

  useEffect(() => {
    async function getCheckoutSessionAction() {
      const subscription = searchParams.get('subscription') as string;
      const topUp = searchParams.get('topUp') as string;

      if (topUp === 'success') {
        captureEvent('Subscription Checkout completed', {
          action: 'TOp up credits',
        });

        await new Promise((resolve) => setTimeout(resolve, 250));
        posthog.reloadFeatureFlags();

        toast.success('Payment successfull.', {
          toastId: 'successfull_payment',
          position: 'bottom-right',
          autoClose: 5000,
        });
        searchParams.delete('topUp');
        setSearchParams(searchParams);
      }

      if (subscription === 'success') {
        captureEvent('Subscription Checkout completed', {
          $set: {
            ['api_subscriber']:
              location.pathname.includes('dashboard-api') || undefined,
            ['app_subscriber']:
              location.pathname.includes('mockup-library') || undefined,
          },
        });

        await new Promise((resolve) => setTimeout(resolve, 250));
        posthog.reloadFeatureFlags();

        toast.success('Payment successfull.', {
          toastId: 'successfull_payment',
          position: 'bottom-right',
          autoClose: 5000,
        });
        searchParams.delete('subscription');
        setSearchParams(searchParams);
      }
    }

    if (searchParams.get('subscription') || searchParams.get('topUp')) {
      getCheckoutSessionAction();
    }
  }, []);

  return (
    <>
      {/* {needManualRefresh && (
        <Flex
          className="service-worker-refresh"
          align={'center'}
          justify={'center'}
          direction={'column'}
          gap={'4'}
        >
          <Text size={'3'} weight={'medium'}>
            New content available, click on reload button to update.
          </Text>
          <Button
            variant="solid"
            onClick={async () => {
              const response = await updateServiceWorker();
              if (typeof response === 'undefined') {
                window.location.reload();
              } else {
                console.log(response);
              }
            }}
          >
            Reload
          </Button>
        </Flex>
      )} */}
      <Onboarding />
      {/* {!isAppPro && (
        <Flex
          className="notification-banner black-fridady"
          align={'center'}
          justify={'center'}
        >
          <Text
            className="text"
            size={'2'}
            onClick={() => {
              captureEvent('Upgrade your plan modal viewed', {
                source: 'Black Friday banner',
              });
              captureEvent('Black Friday banner clicked');
              dispatch(setIsAppPaymentPopupOpen(true));
            }}
          >
            🚨 Use “<b>BF40</b>” at checkout to save 40% on all plans - Ends
            November 30th! 🚨
          </Text>
        </Flex>
      )} */}
      <Flex
        className="app-header"
        justify={'between'}
        align={'center'}
        display={'flex'}
        gap={'4'}
        py={'2'}
        px={'4'}
        pl={'5'}
        style={{
          height: shouldShowHeader() ? '60px' : '0px',
          paddingTop: shouldShowHeader() ? '16px' : '0px',
          paddingBottom: shouldShowHeader() ? '16px' : '0px',
          opacity: shouldShowHeader() ? '1' : '0',
        }}
      >
        <AppPaymentPopup ref={appPaymentPopupRef} />

        {shouldShowHeader() && activeView == 'preview' && (
          <Flex gap={'5'} align={'center'}>
            <Button
              variant="ghost"
              color="gray"
              className="back-button"
              size={'1'}
              onClick={() => navigate(`/my-templates`)}
            >
              <ArrowLeft width="14px" height="14px" className="icon" />
            </Button>
            {!isMobile && (
              <Text weight={'medium'} size={'2'}>
                {mockup?.name}
              </Text>
            )}
          </Flex>
        )}
        {shouldShowHeader() && activeView !== 'preview' && (
          <Button
            variant="ghost"
            color="gray"
            className="back-button"
            onClick={() => goBack()}
          >
            <ArrowLeft width="14px" height="14px" className="icon" />
            {!isMobile && <Text weight={'medium'}>Back</Text>}
          </Button>
        )}
        <Flex align={'center'}></Flex>
        <Flex direction={'row'} align={'center'} justify={'end'} gap={'2'}>
          {shouldShowHeader() && activeView == 'preview' && (
            <Flex align={'center'} justify={'between'} gap={'2'}>
              {!isAppPro && (
                <Button
                  className="get-pro-header-button"
                  size={'1'}
                  color="gray"
                  variant="outline"
                  onClick={() => {
                    captureEvent('Upgrade your plan modal viewed', {
                      source: 'Header "Get Pro" CTA',
                    });
                    dispatch(setIsAppPaymentPopupOpen(true));
                  }}
                >
                  <ProCrownIcon width="18" height="18" />
                  {currentUser?.subscription_trial.is_available ? (
                    <Text className="">Start Free Trial</Text>
                  ) : (
                    <Text className="">Get Pro</Text>
                  )}
                </Button>
              )}
              <GenerateVariationsButton />
            </Flex>
          )}

          {shouldShowHeader() && activeView !== 'preview' && (
            <Flex gap={'5'} align={'center'}>
              {activeAction === 'edit' && activeView === 'update-color' && (
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button
                      variant="ghost"
                      color="gray"
                      size={'1'}
                      disabled={isUpdating && isDeleting}
                    >
                      <TrashBin className="icon" width="18px" height="18px" />
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content style={{ maxWidth: 450 }}>
                    <AlertDialog.Title>Remove Color</AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      Are you sure you want to proceed? Removing this color will{' '}
                      {getNumerOfAssociatedVariations() ===
                      mockupVariations.length
                        ? 'update'
                        : 'remove'}{' '}
                      {getNumerOfAssociatedVariations()} associated{' '}
                      {getNumerOfAssociatedVariations() === 1
                        ? 'variation.'
                        : 'variations.'}
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Cancel>
                        <Button
                          variant="solid"
                          color="red"
                          onClick={() => removeColorVariant()}
                          disabled={
                            isCreatingMockupVariations || isCreatingVariations
                          }
                        >
                          Remove
                        </Button>
                      </AlertDialog.Cancel>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              )}

              {activeAction === 'add' && activeView === 'update-color' && (
                <Button
                  disabled={isAdding}
                  onClick={() => addNewColorVariant()}
                  event-tracker-id="add-new-color-action"
                >
                  <CheckIcon /> Add
                </Button>
              )}
              {activeAction === 'edit' && activeView === 'update-color' && (
                <Button
                  disabled={isUpdating && isDeleting}
                  onClick={() => updateColorVariant()}
                  id="save-asset-edit"
                >
                  <CheckIcon /> Done
                </Button>
              )}

              {activeAction === 'edit' && activeView === 'update-asset' && (
                <AlertDialog.Root>
                  <AlertDialog.Trigger>
                    <Button
                      variant="ghost"
                      color="gray"
                      size={'1'}
                      disabled={isDeletingAsset}
                    >
                      <TrashBin className="icon" width="18px" height="18px" />
                    </Button>
                  </AlertDialog.Trigger>
                  <AlertDialog.Content style={{ maxWidth: 450 }}>
                    <AlertDialog.Title>Remove design asset</AlertDialog.Title>
                    <AlertDialog.Description size="2">
                      Are you sure you want to proceed? Removing this design
                      asset will{' '}
                      {getNumerOfAssociatedVariations() ===
                      mockupVariations.length
                        ? 'update'
                        : 'remove'}{' '}
                      {getNumerOfAssociatedVariations()} associated{' '}
                      {getNumerOfAssociatedVariations() === 1
                        ? 'variation.'
                        : 'variations.'}
                    </AlertDialog.Description>

                    <Flex gap="3" mt="4" justify="end">
                      <AlertDialog.Cancel>
                        <Button variant="soft" color="gray">
                          Cancel
                        </Button>
                      </AlertDialog.Cancel>
                      <AlertDialog.Cancel>
                        <Button
                          variant="solid"
                          color="red"
                          onClick={() => removeDesignAsset()}
                        >
                          Remove
                        </Button>
                      </AlertDialog.Cancel>
                    </Flex>
                  </AlertDialog.Content>
                </AlertDialog.Root>
              )}

              {activeAction === 'edit' && activeView === 'update-asset' && (
                <Button
                  onClick={() => goBack()}
                  className="done-button"
                  color="blue"
                  id="done-asset-edit"
                >
                  <CheckIcon /> Done
                </Button>
              )}

              {activeAction === 'add' &&
                activeView === 'create-ai-background' && (
                  <Button
                    disabled={!aiBackgroundImage}
                    loading={isApplying}
                    onClick={() => {
                      if (isAppPro) {
                        addScene();
                      } else {
                        captureEvent('Upgrade your plan modal viewed', {
                          source: 'Add AI Scene',
                        });
                        dispatch(setIsAppPaymentPopupOpen(true));
                      }
                    }}
                  >
                    <CheckIcon /> Add Scene
                  </Button>
                )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Outlet />
    </>
  );
};
