import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';
import { store } from './redux/store';
import { App } from './App';
import { PostHogProvider } from 'posthog-js/react';
import '@radix-ui/themes/styles.css';
import './theme-config.scss';
import * as Sentry from '@sentry/react';
import { HelmetProvider } from 'react-helmet-async';
import 'posthog-js/dist/recorder';
import 'posthog-js/dist/surveys';
import 'posthog-js/dist/exception-autocapture';
import 'posthog-js/dist/tracing-headers';
import 'posthog-js/dist/web-vitals';
import { PostHogConfig } from 'posthog-js';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GTM = {
  gtmId: 'GTM-T78C9HX',
};

TagManager.initialize(GTM);

const options: Partial<PostHogConfig> | undefined = {
  api_host: PUBLIC_POSTHOG_HOST,
  opt_in_site_apps: true,
  disable_session_recording: !parseInt(IS_PRODUCTION),
  capture_pageview: !!parseInt(IS_PRODUCTION),
  autocapture: !!parseInt(IS_PRODUCTION),
  debug: !parseInt(IS_PRODUCTION),
  opt_out_capturing_by_default: !parseInt(IS_PRODUCTION),
  disable_external_dependency_loading: false,
};

const apiKey = PUBLIC_POSTHOG_KEY;

Sentry.init({
  dsn: PUBLIC_SENTRY_KEY,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://app.dynamicmockups.com'],
    }),
    new Sentry.Replay(),
  ],
  environment: !!parseInt(IS_PRODUCTION) ? 'production' : 'development',
  enableTracing: false,
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <PostHogProvider apiKey={apiKey} options={options}>
    <HelmetProvider>
      <Provider store={store}>
        <ToastContainer transition={Slide} />
        <App />
      </Provider>
    </HelmetProvider>
  </PostHogProvider>
);

